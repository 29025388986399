import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="py-16 prose text-center">
        <h2 className="p-3 my-8 text-5xl font-bold text-center">404</h2>
        <p>Shucks. That page can&apos;t be found.</p>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
